<script lang="ts">
  /**
   * Imports
   * ---------------------------------------------------------------------------
   */

  import type { BookEntry } from "@customTypes/entries";
  import { convertStatamicAssetToCDN } from "@utils/general.ts";
  import clsx from "clsx";
  import BookImage from "./BookImage.svelte";

  /**
   * Props
   * ---------------------------------------------------------------------------
   */

  /** Book Data */
  export let book: BookEntry;

  /** If true, then show debugging background colors and borders */
  export let debug: boolean = false;

  /** Additional Tailwind class utilities to add to the top level element */
  export let { class: classProp = "" } = $$props;

  /**
   * Data / State
   * ---------------------------------------------------------------------------
   */
  const href = `/books/${book.slug}`;
  const verticalImageUrl = convertStatamicAssetToCDN(book.images.vertical.id);
</script>

<!-- Template ----------------------------------------------------------------->

<a
  {href}
  class={clsx(
    "min-w-48 max-w-72 p-4 text-white flex flex-col items-center",
    "hover:scale-105 scale-100",
    debug && "bg-s300",
    classProp,
  )}
>
  <BookImage
    url={verticalImageUrl}
    altText={book.title}
    class="h-72 object-scale-down"
  />
  <div class={clsx("pt-8 text-center", debug && "bg-y300")}>
    <span class="wah-heading-5 text-s100">{book.title}</span>
  </div>
  <div class={clsx("wah-body-base text-center", debug && "bg-g300")}>
    {book.subtitle}
  </div>
  <div class={clsx("wah-body-small text-center", debug && "bg-r300")}>
    {book.authors.map((author) => author.title).join(", ") +
      (!!book.publication_date
        ? " - " + new Date(book.publication_date).getFullYear()
        : "")}
  </div>
</a>
