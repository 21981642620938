<script lang="ts">
  /**
   * Imports
   * ---------------------------------------------------------------------------
   */

  import clsx from "clsx";

  /**
   * Props
   * ---------------------------------------------------------------------------
   */

  /** Image URL */
  export let url: string | undefined;

  /** Image alt text */
  export let altText: string = "";

  /** Additional Tailwind class utilities to add to the top level element */
  export let { class: classProp = "" } = $$props;
</script>

<!-- Template ----------------------------------------------------------------->

<img
  src={url}
  alt={altText}
  class={clsx("drop-shadow-[5px_35px_35px_rgba(255,255,255,0.20)]", classProp)}
/>
