<script lang="ts">
  /**
   * Imports
   * ---------------------------------------------------------------------------
   */

  import type { BookEntry } from "@customTypes/entries";
  import BookCard from "./BookCard.svelte";
  import {
    type EmblaCarouselType,
    type EmblaOptionsType,
    type EmblaPluginType,
  } from "embla-carousel";
  import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
  import emblaCarouselSvelte from "embla-carousel-svelte";
  import { onDestroy } from "svelte";
  import Icon from "@elements/Icon.svelte";
  import clsx from "clsx";

  /**
   * Props
   * ---------------------------------------------------------------------------
   */

  /** Data items to display in the slider */
  export let items: BookEntry[] = [];

  /**
   * Data / State
   * ---------------------------------------------------------------------------
   */

  let emblaCanScrollNext = false;
  let emblaCanScrollPrev = false;
  let isMouseHovering = false;

  $: isScrollNextVisible = emblaCanScrollNext && isMouseHovering;
  $: isScrollPrevVisible = emblaCanScrollPrev && isMouseHovering;

  /**
   * Embla Carousel
   * ---------------------------------------------------------------------------
   */

  let emblaAPI: EmblaCarouselType;
  let plugins: EmblaPluginType[] = [WheelGesturesPlugin()];
  let options: EmblaOptionsType = {
    skipSnaps: true,
    loop: false,
  };

  /** Embla callback: init */
  function onInit(event: CustomEvent<EmblaCarouselType>) {
    emblaAPI = event.detail; // save Embla API reference
    emblaAPI.on("select", onSelect); // add onSelect callback
    updateCanScrollState();
  }

  /** Embla callback: selected scroll snap change. */
  function onSelect(event: any) {
    updateCanScrollState();
  }

  function updateCanScrollState() {
    emblaCanScrollNext = emblaAPI.canScrollNext();
    emblaCanScrollPrev = emblaAPI.canScrollPrev();
  }

  /**
   * Functions
   * ---------------------------------------------------------------------------
   */

  function onMouseEnter() {
    isMouseHovering = true;
  }

  function onMouseLeave() {
    isMouseHovering = false;
  }

  function onPrevClick() {
    emblaAPI.scrollPrev();
  }

  function onNextClick() {
    emblaAPI.scrollNext();
  }

  /**
   * Lifecycle
   * ---------------------------------------------------------------------------
   */

  onDestroy(() => {
    if (emblaAPI) {
      emblaAPI.destroy();
    }
  });

  /**
   * Data
   * ---------------------------------------------------------------------------
   */

  const nextPrevButtonClasses =
    "#hidden absolute top-0 h-full w-14 bg-black/30 transition-all duration-300 stroke-white";
</script>

<!-- Template ----------------------------------------------------------------->

<div class="embla">
  <!-- embla viewport -->
  <div
    class="embla__viewport"
    use:emblaCarouselSvelte={{ options, plugins }}
    role="region"
    aria-label="Carousel"
    on:emblaInit={onInit}
    on:mouseenter={onMouseEnter}
    on:mouseleave={onMouseLeave}
  >
    <!-- main container and slides -->
    <div class="embla__container container">
      {#each items as item}
        <div class="embla__slide">
          <BookCard book={item} />
        </div>
      {/each}
    </div>
    <!-- prev button -->
    <button
      on:mouseenter={onMouseEnter}
      on:mouseleave={onMouseLeave}
      on:click={onPrevClick}
      aria-label="Previous slide"
      class={clsx(
        nextPrevButtonClasses,
        "embla__prev left-0",
        isScrollPrevVisible ? "opacity-100" : "opacity-0",
      )}
    >
      <span class="sr-only">Prev</span>
      <Icon name="chevron-left" size="100%" />
    </button>
    <!-- next button -->
    <button
      on:mouseenter={onMouseEnter}
      on:mouseleave={onMouseLeave}
      on:click={onNextClick}
      aria-label="Next slide"
      class={clsx(
        nextPrevButtonClasses,
        "embla__next right-0",
        isScrollNextVisible ? "opacity-100" : "opacity-0",
      )}
    >
      <span class="sr-only">Next</span>
      <Icon name="chevron-right" size="100%" />
    </button>
  </div>
</div>

<style>
  .embla {
    overflow: hidden;
    position: relative;
    --slide-gap: 2rem;
  }
  .embla__container {
    display: flex;
    gap: var(--slide-gap);
    backface-visibility: hidden;
  }
  .embla__slide {
    flex: 0 0 16rem;
    min-width: 0;
  }
</style>
